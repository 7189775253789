// ./components/home/Techstack.scss
@import '../../_variables';

.tech-stack-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 10px;
  flex-wrap: wrap;

  @media (max-width: $screen-size) {
    flex-wrap: wrap;
    padding: 0 10px;
  }
}

.loading-message {
  font-size: 40px;
  font-weight: bold;
  color: #5fade1;
  padding: 40px;
}


.skill-icon {
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  filter: drop-shadow(2px 2px 5px #9a9a9a); 


  img {
    width: 120px;
  }

  &:hover {
    transform: scale(1.2);
  }

  @media (max-width: $screen-size) {
    margin-bottom: 10px; /* Add spacing between icons on smaller screens */
  }
}
