// ./components/Home/Introduction.scss
@import '../../variables';
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arima+Madurai&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);

@font-face {
  font-family: 'Open Sans';
  src: url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
}

.introduction-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 2; /* Increase the z-index value */
  

  @media (max-width: $big-screen) {
    padding-top: 100px;
    
    .left-column {
      padding-right: 20px;
    }
  }
  
  @media (max-width: $screen-size) {
    flex-direction: column;
    text-align: center;

    .right-column {
      order: -1; /* Move the image to the top */
    }
    .left-column {
      text-align: center;
      width: 100%;
    }

    .name {
      font-size: 3em;
    }
  }
}


.left-column {
  flex: 2; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 100vh;
}


.right-column {
    flex: 1 1; /* Adjust the width as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  .profile-image {
    width: 100%; /* Make sure the image takes the full width of its container */
    max-width: 400px; /* Adjust the maximum width as needed */
    height: auto;
    border-radius: 50%; /* Make the image circular */
  }

  @media (max-width: $screen-size) {
        
    .profile-image {
      width: 80%;
    }
  }
}

.name {
  font-size: 7.2em;
  margin: 0;
  font-family: 'Inconsolata', monospace;
  
}

.first-name {
  color: #464646; /* Adjust the shade of grey for first name */
  font-weight: 800;
  text-shadow: (#969696 1px 1px 0px);



}

.last-name {
  color: #7e7e7e; /* Adjust the shade of grey for last name */
  font-weight: 700;
  text-shadow: (#c0c0c0 1px 1px 0px);
  
}

.job-title {
  font-size: 1.8em;
  margin: 0;
  font-style: italic;
  font-family: 'Inconsolata', monospace;
  color: #4f4f4f; /* Adjust the color as needed */
}

.about-me {
  font-size: 1.5em;
  flex: 2; /* Adjust the width as needed */
  color: #ffffff; /* Adjust the shade of grey for last name */
  padding: 0 20px; /* Add some spacing to paragraphs */
  font-family: 'Inconsolata', monospace;
}
.about-me p {
  margin-bottom: 10px; /* Add spacing between paragraphs */
  text-shadow: (black 1px 1px 0px);
}
