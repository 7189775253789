// ./pages/Home/Home.scssa
@import '../../variables';

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;

  @media (max-width: $screen-size) {
    display: block;
    height: 100%;
  }
  
  .introduction {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    min-height: 60vh;
    width: 100%;
    
    @media (max-width: $screen-size) {
      min-height: 95vh;
    }
  }

  .tech-stack {
    margin-top: auto;
    height: 20%;
    width: 100%;
    z-index: 2;
    padding-bottom: 5%;
  }
}
