.App {
  text-align: center;
}

body {
  background-image: linear-gradient(35deg,
  rgb(24, 118, 119) 0%,
  rgb(126, 117, 52) 30%,
  rgb(180, 156, 62) 70%,
  rgb(241, 164, 0) 100%
  );
 

  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling on smaller screens */
  
}


nav {
  background-color: #333;
  color: white;
  padding: 10px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
  color: white;
}
