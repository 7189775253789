// ./components/Navbar/Navbar.scss
@import '../../_variables';


nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #333;
  padding: 10px;
  z-index: 1000;
  box-shadow: #000000cf -20px 0px 20px 0px;
  .left {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline;
      margin-right: 15px;
    }

    a {
      color: white;
      text-decoration: none;
    }
  }

  .right {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-end;
    }

    li {
      margin-left: 0px;
      padding-right: 10px;
    }
  }

  .link-button {
    text-decoration: none;  
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    color: white;
    padding: 0;
    margin-right: 15px;
  }

}
