// ./components/Contact/Contact.scss
@import '../../_variables';

.contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* Apply background blur */
  z-index: 999; /* Ensure the contact overlay is above other elements */
  text-shadow: black -1px 1px;
  font-size: 1.4em;
  
}

.contact-popup {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


//frontend/src/styles/Contact.scss

/* General input styles */
input, select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

/* Submit button styles */
input[type=submit], .button {
  background-color: #04AA6D;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: #535353 -1px 1px 10px 0px;
}

/* Your other styles remain unchanged */



.contact-success {
  font-size: 5rem;
  color: #fafafa;
  p {
    text-shadow: -3px 3px 6px black;

  }
}

@media (max-height: 500px) {
  .contact-me {
    padding-top: 70px;
  }
}